<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Propostas"
          buttonAddItemText="Nova Proposta"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :showLoadingModal="showLoadingModal"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="propostas"
              :loading="loadingTable"
              :search="searchTextField"
            >
              <!-- actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="toggleModalLateral(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                        <span>Visualizar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="editar(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                        <span>Editar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="remover(item, true)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                        <span>Apagar</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="getColor(item.status)"
                  :class="`${getColor(item.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
            </v-data-table>
          </template>
          <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn color="error" @click="remover()">Excluir</v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    ref="cnpj"
                    label="CNPJ"
                    placeholder="Escreva o CNPJ do responsável da proposta"
                    required
                    outlined
                    dense
                    v-model="proposta.cnpj"
                    data-vv-as="CNPJ"
                    persistent-hint
                    :hint="errors.first('cnpj')"
                    :error="errors.collect('cnpj').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="cnpj"
                    name="cnpj"
                    v-mask="'##.###.###/####-##'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    ref="nome_responsavel"
                    label="Nome do responsável"
                    placeholder="Escreva o nome do responsável da proposta"
                    required
                    outlined
                    dense
                    v-model="proposta.nome_responsavel"
                    data-vv-as="nome"
                    persistent-hint
                    :hint="errors.first('nome_responsavel')"
                    :error="
                      errors.collect('nome_responsavel').length ? true : false
                    "
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="nome_responsavel"
                    name="nome_responsavel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    ref="email_responsavel"
                    label="E-mail"
                    placeholder="Escreva o e-mail do responsável da proposta"
                    required
                    outlined
                    dense
                    v-model="proposta.email_responsavel"
                    data-vv-as="e-mail"
                    persistent-hint
                    :hint="errors.first('email_responsavel')"
                    :error="
                      errors.collect('email_responsavel').length ? true : false
                    "
                    v-validate="'required|email'"
                    data-vv-validate-on="change"
                    id="email_responsavel"
                    name="email_responsavel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    ref="produtos"
                    label="Produtos"
                    placeholder="Selecione um ou mais produtos"
                    :items="produtos"
                    item-text="nome"
                    item-value="id"
                    outlined
                    dense
                    multiple
                    v-model="proposta_produtos"
                    data-vv-as="produtos"
                    persistent-hint
                    :hint="errors.first('produtos')"
                    :error="errors.collect('produtos').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="produtos"
                    name="produtos"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.nome }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{
                          proposta_produtos && proposta_produtos.length - 1
                        }}
                        outros)</span
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="editedIndex">
                  <v-select
                    ref="status"
                    label="Status"
                    placeholder="Selecione Status da sua empresa"
                    :items="SelectItensStatus"
                    dense
                    outlined
                    v-model="proposta.status"
                    data-vv-as="status"
                    persistent-hint
                    :hint="errors.first('status')"
                    :error="errors.collect('status').length ? true : false"
                    v-validate="
                      'required|included:Aberta,Expirada,Pendente,Aprovada,Cancelada'
                    "
                    data-vv-validate-on="change"
                    id="status"
                    name="status"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="proposta.status === 'Cancelada' && editedIndex"
                >
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        ref="motivo"
                        label="Motivo"
                        placeholder="Selecione o motivo do cancelamento"
                        :items="motivos"
                        item-text="descricao"
                        item-value="id"
                        dense
                        outlined
                        v-model="proposta_motivo"
                        @change="atualizarListaSubmotivos(proposta_motivo)"
                        data-vv-as="motivo"
                        persistent-hint
                        :hint="errors.first('motivo')"
                        :error="errors.collect('motivo').length ? true : false"
                        v-validate="{
                          required_if: proposta.status == 'Cancelada',
                          included: arrayMotivos,
                        }"
                        data-vv-validate-on="change"
                        id="motivo"
                        name="motivo"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="proposta_motivo && editedIndex"
                    >
                      <v-radio-group
                        v-for="(submotivo, i) in arrayMotivoSubmotivos"
                        :key="i"
                        v-model="proposta_submotivo"
                      >
                        <v-radio
                          :label="submotivo.descricao"
                          :value="submotivo.id"
                          data-vv-as="submotivo"
                          persistent-hint
                          :hint="errors.first('submotivo')"
                          :error="
                            errors.collect('submotivo').length ? true : false
                          "
                          v-validate="{
                            required_if: proposta_submotivo == 0,
                          }"
                          data-vv-validate-on="change"
                          id="submotivo"
                          name="submotivo"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.cnpj`]="{ item }">
        <span v-if="item.cnpj">{{ item.cnpj | cnpjMask() }}</span>
      </template>
      <template v-slot:[`item.produtos_propostas`]="{ item }">
        <div
          v-if="item.produtos_propostas && item.produtos_propostas.length > 0"
        >
          <v-chip-group active-class="primary--text" column>
            <v-chip v-for="items in item.produtos_propostas" :key="items.id">
              <v-icon v-if="items.icone" left>{{ items.icone }}</v-icon>
              {{ items.nome }}</v-chip
            >
          </v-chip-group>
        </div>
        <span v-else>Não possui produtos.</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          :class="`${getColor(item.status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
    </ModalLateral>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components//ModalLateral.vue";
import store from "@/store";

export default {
  name: "Painel-Propostas",
  inject: ["$validator"],
  components: { ModalLateral, Crud },
  data: () => ({
    logotipo: logo,
    nameForm: "Nova Proposta",
    showLoadingModal: false,
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    proposta: {
      nome_responsavel: "",
      email_responsavel: "",
      cnpj: "",
      produtos: [],
    },
    proposta_produtos: [],
    proposta_motivo: 0,
    proposta_submotivo: 0,
    SelectItensStatus: [
      "Aberta",
      "Expirada",
      "Pendente",
      "Aprovada",
      "Cancelada",
    ],
    arrayMotivos: [],
    arrayMotivoSubmotivos: [],
    searchTextField: "",
    dialog: false,
    editedIndex: false,
    loadingTable: false,
    dialogDelete: false,
    headers: [
      { text: "Responsável", align: "start", value: "nome_responsavel" },
      { text: "E-mail", value: "email_responsavel" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Uuid", align: "start", value: "uuid" },
      { text: "CNPJ", align: "start", value: "cnpj" },
      { text: "Responsável", align: "start", value: "nome_responsavel" },
      { text: "E-mail", align: "start", value: "email_responsavel" },
      { text: "Produtos", value: "produtos_propostas" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],
    loadingButtons: {
      buttonVisibility: false,
      buttonEdit: false,
    },
    item: [],
  }),
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    propostas: {
      get() {
        return this.$store.getters["propostas/getPropostas"];
      },
    },
    produtos: {
      get() {
        return this.$store.getters["propostas/getProdutos"];
      },
    },
    motivos: {
      get() {
        return this.$store.getters["propostas/getMotivos"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["propostas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },
  created() {
    this.$store.dispatch("propostas/initialize");
  },

  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loadingTable = true;
          this.proposta.produtos = [];

          this.proposta_produtos.map((el) => {
            if (el.id == undefined) {
              this.proposta.produtos.push(el);
            } else {
              this.proposta.produtos.push(el.id);
            }
          });
          this.proposta.motivo = this.proposta_motivo;
          this.proposta.submotivo = this.proposta_submotivo;
          this.proposta.cnpj = this.$options.filters.numbersOnly(
            this.proposta.cnpj
          );

          if (this.editedIndex == false) {
            this.$store
              .dispatch("propostas/save", this.proposta)
              .then((data) => {
                this.loadingTable = false;
                if (data != "NAO_AUTORIZADO") {
                  this.dialog = false;
                  this.propostas.push(data);

                  this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                    visibilidade: true,
                    mensagem: "Proposta cadastrada com sucesso.",
                    cor: "green",
                  });
                }
              })
              .catch(() => {
                this.loadingTable = false;
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem:
                    "Erro ao cadastrar proposta, tente novamente mais tarde",
                  cor: "red",
                });
              })
              .finally(() => {
                this.loadingTable = false;
              });
          } else {
            const index = this.propostas.indexOf(this.proposta);

            this.$store
              .dispatch("propostas/edit", this.proposta)
              .then((data) => {
                this.loadingTable = false;
                if (data != "NAO_AUTORIZADO") {
                  this.propostas.splice(index, 1, data);
                  this.dialog = false;

                  this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                    visibilidade: true,
                    mensagem: "Proposta editada com sucesso.",
                    cor: "green",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem:
                    "Erro ao editar proposta, tente novamente mais tarde",
                  cor: "red",
                });
              })
              .finally(() => {
                this.loadingTable = false;
              });
          }

          return this.fechar();
        }
      });
    },
    editar(item) {
      this.buscarMotivos();
      if (this.editedIndex == false) {
        this.loadingButtons.buttonEdit = true;
        this.$store
          .dispatch("propostas/getPropostaByUuid", item.uuid)
          .then((response) => {
            this.nameForm = "Editar proposta: " + response.nome_responsavel;
            this.proposta = response;
            this.editedIndex = true;
            this.proposta_produtos = response.produtos_propostas.map(function (
              el
            ) {
              return parseInt(el.id);
            });
            this.dialog = true;
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Oops, aconteceu um erro por aqui. Tente mais tarde.",
            });
          })
          .finally(() => {
            this.loadingButtons.buttonEdit = false;
          });
      }

      this.editedIndex = false;
      this.dialog = false;
    },
    fechar() {
      this.dialog = false;
      this.editedIndex = false;
      this.nameForm = "Nova proposta";
      this.proposta_produtos = [];
      this.proposta_motivo = 0;
      this.proposta_submotivo = 0;
      this.proposta = {
        nome_responsavel: "",
        email_responsavel: "",
        cnpj: "",
        created_at: "",
        status: "",
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingTable = true;
        this.$store
          .dispatch("propostas/deletarProposta", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingTable = false;
            this.dialogDelete = false;
          });
      }
    },
    //Modal Lateral
    handleClickRow(value) {
      this.toggleModalLateral(value);
    },
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.$store
          .dispatch("propostas/getPropostaByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome_responsavel
              ? response.nome_responsavel
              : "";
            this.modalLateral.item = response ? response : [];

            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Houve um erro ao buscar por dados da proposta.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingButtons.buttonVisibility = false;
          });
      } else {
        this.modalLateral.show = false;
      }

      //
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "Cancelada") return "error";
      if (status == "Aprovada") return "success";
      if (status == "Pendente") return "info";
      if (status == "Expirada") return "error";
      if (status == "Aberta") return "warning";
      return "primary";
    },
    atualizarListaSubmotivos(proposta_motivo) {
      this.arrayMotivos = [];
      this.arrayMotivoSubmotivos = [];

      this.motivos.forEach((motivo) => {
        this.arrayMotivos.push(motivo.id);
        if (motivo.id == proposta_motivo) {
          motivo.motivo_submotivo.forEach((submotivo) => {
            this.arrayMotivoSubmotivos.push(submotivo);
          });
        }
      });
    },
    buscarMotivos() {
      if (!this.motivos) {
        this.showLoadingModal = true;

        this.$store
          .dispatch("propostas/buscarMotivos")
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar motivos. Tente novamente mais tarde.",
              cor: "red",
            });
          })
          .finally(() => {
            this.showLoadingModal = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
